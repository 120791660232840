import BackendAuthService from "./BackendAuthService";

export default class InterfazCatService extends BackendAuthService {

	constructor() {
		super();
	}

	/**
 	* Solicitud GET usando AJAX
	* @param {string} url - La URL a la que se realizará la solicitud.
	* @param {object} params - Los parámetros opcionales que se adjuntarán a la URL.
	* @returns {Promise} - Una promesa que se resuelve con la respuesta de la solicitud.
 	*/
	doGet(url, params) {
		let config = {
			method: 'GET',
		}
		if (params) {
			config['params'] = params
		}
		return this.httpCall(url, config);
	}

	/**
	* Realiza una solicitud POST utilizando AJAX.
	* @param {string} url - La URL a la que se realizará la solicitud.
	* @param {FormData|Object} data - Los datos que se enviarán en la solicitud.
	* @returns {Promise} - Una promesa que se resuelve con la respuesta de la solicitud.
 	*/
	doPost(url, data) {
		let config = {
			method: 'POST',
		}
		if (data) {
			config['data'] = data
		}
		return this.httpCall(url, config);
	}

	/**
	 * Enviar formulario Motor Audiencias Email Comercial
	 */
	submitFormEmailComercial(data) {
		let url = 'api/v1/motoraudiencias/email-comercial-form'
		return this.doPost(url, data)
	}


	/**
	 * Enviar formulario Motor Audiencias Email Ofertas
	 */
	submitFormEmailOfertas(data) {
		let url = 'api/v1/motoraudiencias/email-ofertas-form'
		return this.doPost(url, data)
	}

	/**
	 * Obtinene informacion de un dag run por su id
	 */
	getJobInfoById(data) {
		let url = 'api/v1/motoraudiencias/job/info/'
		return this.doGet(url, data)
	}

	/**
	 * Obtinene las categorias, subcategorias y la clase
	 */
	getCategoriesWithSubclasifitacions() {
		let url = 'api/v1/motoraudiencias/inputs/categories-with-subclasifications'
		return this.doGet(url)
	}

	/**
	 * Enviar formulario Motor Audiencias Push
	 */
	submitFormPush(data) {
		let url = 'api/v1/motoraudiencias/form-push'
		return this.doPost(url, data)
	}

	

}

